import { render, staticRenderFns } from "./Project.vue?vue&type=template&id=15f2b40e&"
import script from "./Project.vue?vue&type=script&lang=js&"
export * from "./Project.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15f2b40e')) {
      api.createRecord('15f2b40e', component.options)
    } else {
      api.reload('15f2b40e', component.options)
    }
    module.hot.accept("./Project.vue?vue&type=template&id=15f2b40e&", function () {
      api.rerender('15f2b40e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/project/Project.vue"
export default component.exports