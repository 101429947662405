<template>
  <div>
    <!-- <template v-if="project.events != null">
      <Traceability :project="project" v-if="project.events.length > 0"/>
      <br>
    </template> -->
    <div v-if="showInformation">
      <ProjectHeader :project="project"/>
      <br>
      <ProjectRestrictions :project="project" v-if="project.observation_restrictions.length > 0"/>
      <br>
      <ProjectConceptGeneral :project="project"/>
      <br>
      <ProjectObservations :project="project"/>
      <br v-if="project.observation_restrictions.length > 0">
      <br v-if="project.credit_validation">
      <ProjectCreditValidation :project="project" v-if="project.credit_validation"/>
      <template v-if="project.judicial_verification != null">
        <br v-if="project.judicial_verification.length > 0">
        <ProjectJudicialVerification :project="project" v-if="project.judicial_verification.length > 0"/>
      </template>
      <br>
      <ProjectDataForm :project="project"/>
      <template v-if="project.project_photos != null">
        <br>
        <ProjectPhotos :project="project" v-if="project.project_photos.length > 0"/>
      </template>
      <template v-if="project.project_archives != null">
        <br>
        <ProjectArchives :project="project" v-if="project.project_archives.length > 0"/>
      </template>
      <br>
      <vx-card>
        <vs-button
          v-if="project.project_type_name !== 'Prueba de poligrafía' && project.polygraph_attachment.slice(0,5) !== '//s3.'"
          class="button-report"
          type="gradient"
          style="background: #640064;"
          @click="linkToReport()"
          target="_blank"
        >
          Descargar reporte
        </vs-button>
        <vs-button
          v-else-if="project.project_type_name === 'Prueba de poligrafía' && project.polygraph_attachment.slice(0,5) == '//s3.'"
          class="button-report"
          type="gradient"
          style="background: #640064;"
          :href="`https:${project.polygraph_attachment}`"
          target="_blank"
        >
          Descargar reporte de poligrafía
        </vs-button>
        <vs-button
          v-else
          class="button-report"
          type="gradient"
          style="background: #640064;"
          @click="linkToReport()"
          target="_blank"
        >
          Descargar reporte
        </vs-button>
        <vs-button
          class="button-report"
          type="gradient"
          style="background: #640064;"
          @click="linkToResumen()"
          target="_blank"
        >
          Descargar resumen
        </vs-button>
      </vx-card>
      <br>
      <modal-message :modalActive="progressReport"/>
    </div>
  </div>
</template>
<script>
// import Traceability from './Traceability.vue'
import ProjectHeader from './ProjectHeader.vue'
import ProjectConceptGeneral from './ProjectConceptGeneral.vue'
import ProjectDataForm from './ProjectDataForm.vue'
import ProjectObservations from './ProjectObservations.vue'
import ProjectCreditValidation from './ProjectCreditValidation.vue'
import ProjectJudicialVerification from './ProjectJudicialVerification.vue'
import ProjectAnalyst from './ProjectAnalyst'
import ProjectPhotos from './ProjectPhotos'
import ProjectArchives from './ProjectArchives'
import api from '../../mixins/api.js'
import { mapGetters } from 'vuex'
import ModalMessage from './../ModalMessage'
import { eventBus } from './../../main'
import ProjectRestrictions from './ProjectRestrictions.vue'
export default {
  mixins: [api],
  props: {
    popupShowActive: Boolean,
    project: Object,
  },
  data (){
    return{
      colorx:"rgba(255,255,255,255)",
      progressReport:false,
      showInformation: true
    }
  },
  methods: {
    async linkToReport(){
      let fileName = this.project.project_person_full_name + " - " + this.project.project_type_name + " - " + this.project.person_id
      console.log(fileName)
      await this.linkReport(this.project.id,this.userInfo.user.id,fileName)
    },
    goToProject(){
      this.$router.push('project')
    },
    async linkToResumen(){
      let fileName = this.project.project_person_full_name + " - " + this.project.project_type_name + " - " + this.project.person_id
      await this.linkResumen(this.project.id,this.userInfo.user.id,fileName)
    }
  },
  created(){

  },
  mounted(){
    eventBus.$on('toggleInformationFull', (value) => {
      this.showInformation = value
      console.log('Here 1:', value)
    })
  },
  components:{
    // Traceability,
    ProjectHeader,
    ProjectConceptGeneral,
    ProjectDataForm,
    ProjectObservations,
    ProjectCreditValidation,
    ProjectJudicialVerification,
    ProjectAnalyst,
    ProjectPhotos,
    ProjectArchives,
    ModalMessage,
    ProjectRestrictions
  },
  computed:{
    ...mapGetters(['userInfo'])
  }

}
</script>

<style>
  .vs-popup{
    width: 80% !important;
  }
  .button-report{
    margin-right: 1rem !important;
  }
</style>
