<template>
  <div>
    <vx-card>        
      <div class="text-center">
        <h4>Archivos</h4>        
      </div> 
      <br>  
      <vs-list>
        <vs-list-item :title="project_archive.title" v-for="(project_archive, index_project_archive) in project.project_archives" :key="index_project_archive">
          <vs-button class="button-report" type="gradient" style="background: #640064;" @click="linkArchive(project_archive.url)">Descargar archivo</vs-button>    
        </vs-list-item>
      </vs-list>           
    </vx-card>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  props: {
    project: Object
  },
  data() {
    return {
      clientLogo: '',
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      }
    }
  },
  methods: {
    linkArchive(urlArchive){
      if(urlArchive.includes('/system')) {
        let newUrl = urlArchive.split('/')
        newUrl.splice(newUrl.indexOf('system'),1)
        newUrl = newUrl.join('/')
        window.open(`${newUrl}`,'_blank')
      }else{
        window.open(`${urlArchive}`,'_blank')
      }
    }
  },
  created(){

  },
  mounted(){

  },
  components:{
    swiper,
    swiperSlide
  }
}
</script>

<style>

</style>