<template>
  <div>
    <vx-card>        
      <div class="text-center">
        <h4>Apoyo fotográfico</h4>        
      </div> 
      <br>       
      <swiper :options="swiperOption">
        <swiper-slide v-for="(project_photo, index_project_photo) in project.project_photos" :key="index_project_photo">
          <img class="responsive" :src="linkArchive(project_photo.url)" :alt="project_photo.title">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>      
    </vx-card>
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
  props: {
    project: Object
  },
  data() {
    return {
      clientLogo: '',
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          }
        }
      }
    }
  },
  methods: {
    linkArchive(urlArchive){
      if(urlArchive.includes('/system')) {
        let newUrl = urlArchive.split('/')
        newUrl.splice(newUrl.indexOf('system'),1)
        newUrl = newUrl.join('/')
        return newUrl
      }else{
        return urlArchive
      }
    }
  },
  created(){

  },
  mounted(){

  },
  components:{
    swiper,
    swiperSlide
  }
}
</script>

<style>

</style>