var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [_vm._v("Archivos")]),
          ]),
          _c("br"),
          _c(
            "vs-list",
            _vm._l(
              _vm.project.project_archives,
              function (project_archive, index_project_archive) {
                return _c(
                  "vs-list-item",
                  {
                    key: index_project_archive,
                    attrs: { title: project_archive.title },
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-report",
                        staticStyle: { background: "#640064" },
                        attrs: { type: "gradient" },
                        on: {
                          click: function ($event) {
                            return _vm.linkArchive(project_archive.url)
                          },
                        },
                      },
                      [_vm._v("Descargar archivo")]
                    ),
                  ],
                  1
                )
              }
            ),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }