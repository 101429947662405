<template>
  <div class="">
    <ProjectInformationFull :project="projectShow"/>
  </div>
</template>
<script>

import themeConfig from '@/../themeConfig.js';
const VxTour = () => import('@/components/VxTour.vue')
import ProjectInformationFull from '../../../components-globalwork/projects/ProjectInformationFull'
import api from '../../../mixins/api.js'
import { mapGetters } from 'vuex'

export default {
  props:['id'],
  mixins: [api],
  data(){
    return{
      projectShow: {},
      disableThemeTour: themeConfig.disableThemeTour,
      steps: [
        // {
        //     target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.project > div.vx-card__collapsible-content.vs-con-loading__container > div > div.btn-group > button:nth-child(1) > span.vs-button-text.vs-button--text',
        //     content: 'Crear proceso, Buscar proceso, Exportar procesos'
        // },
        // {
        //     target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.project > div.vx-card__collapsible-content.vs-con-loading__container > div > div.vs-component.vs-con-table.stripe.vs-table-primary > div > div > table > tr:nth-child(3) > td:nth-child(15)',
        //     content: 'Detalle de proceso'
        // },
        // {
        //     target: '#content-area > div.content-wrapper > div.router-view > div > div > div.vx-card.project > div.vx-card__collapsible-content.vs-con-loading__container > div > div.vs-component.vs-con-table.stripe.vs-table-primary > div > div > table > tr:nth-child(3) > td:nth-child(16)',
        //     content: 'Subir Archivos'
        // }
      ]
    }
  },

  methods:{

  },
  mounted: function(){
    // this.project_id = this.$route.params.id
    // console.log("mounted " + this.project_id)
  },
  created: function(){
    var user_id = this.userInfo.user.id
    var path = "/api/v1/projects/" + this.currentProject.id + "?user_id="+user_id;
    var url = this.url()+path
    this.requestGetWithoutContent(url, {}).then(response => {
      if(response.data.success == true){
        this.projectShow = response.data.data
      }else{
        //console.log("error -> " + JSON.stringify(response.data.message));
      }
    }, (err) => {
      console.log('err users -> ' +  JSON.stringify(err))
    })
  },
  beforeCreate: function () {
  },
  watch: {

  },
  computed: {
    ...mapGetters(['userInfo', 'currentProject'])
  },
  components: {
    VxTour,
    ProjectInformationFull
  }
}
// var Tawk_API=Tawk_API||{};
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/5bd22e89476c2f239ff60683/default';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
// ;(function(d, w, c) {
//   w.ChatraID = 'FcAMLEj7MZgafY8hi';
//   var s = d.createElement('script');
//   w[c] = w[c] || function() {
//       (w[c].q = w[c].q || []).push(arguments);
//   };
//   s.async = true;
//   s.src = 'https://call.chatra.io/chatra.js';
//   if (d.head) d.head.appendChild(s);
// })(document, window, 'Chatra');
</script>
<style>
</style>
