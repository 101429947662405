var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showInformation
      ? _c(
          "div",
          [
            _c("ProjectHeader", { attrs: { project: _vm.project } }),
            _c("br"),
            _vm.project.observation_restrictions.length > 0
              ? _c("ProjectRestrictions", { attrs: { project: _vm.project } })
              : _vm._e(),
            _c("br"),
            _c("ProjectConceptGeneral", { attrs: { project: _vm.project } }),
            _c("br"),
            _c("ProjectObservations", { attrs: { project: _vm.project } }),
            _vm.project.observation_restrictions.length > 0
              ? _c("br")
              : _vm._e(),
            _vm.project.credit_validation ? _c("br") : _vm._e(),
            _vm.project.credit_validation
              ? _c("ProjectCreditValidation", {
                  attrs: { project: _vm.project },
                })
              : _vm._e(),
            _vm.project.judicial_verification != null
              ? [
                  _vm.project.judicial_verification.length > 0
                    ? _c("br")
                    : _vm._e(),
                  _vm.project.judicial_verification.length > 0
                    ? _c("ProjectJudicialVerification", {
                        attrs: { project: _vm.project },
                      })
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("br"),
            _c("ProjectDataForm", { attrs: { project: _vm.project } }),
            _vm.project.project_photos != null
              ? [
                  _c("br"),
                  _vm.project.project_photos.length > 0
                    ? _c("ProjectPhotos", { attrs: { project: _vm.project } })
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm.project.project_archives != null
              ? [
                  _c("br"),
                  _vm.project.project_archives.length > 0
                    ? _c("ProjectArchives", { attrs: { project: _vm.project } })
                    : _vm._e(),
                ]
              : _vm._e(),
            _c("br"),
            _c(
              "vx-card",
              [
                _vm.project.project_type_name !== "Prueba de poligrafía" &&
                _vm.project.polygraph_attachment.slice(0, 5) !== "//s3."
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "button-report",
                        staticStyle: { background: "#640064" },
                        attrs: { type: "gradient", target: "_blank" },
                        on: {
                          click: function ($event) {
                            return _vm.linkToReport()
                          },
                        },
                      },
                      [_vm._v("\n        Descargar reporte\n      ")]
                    )
                  : _vm.project.project_type_name === "Prueba de poligrafía" &&
                    _vm.project.polygraph_attachment.slice(0, 5) == "//s3."
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "button-report",
                        staticStyle: { background: "#640064" },
                        attrs: {
                          type: "gradient",
                          href: "https:" + _vm.project.polygraph_attachment,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          "\n        Descargar reporte de poligrafía\n      "
                        ),
                      ]
                    )
                  : _c(
                      "vs-button",
                      {
                        staticClass: "button-report",
                        staticStyle: { background: "#640064" },
                        attrs: { type: "gradient", target: "_blank" },
                        on: {
                          click: function ($event) {
                            return _vm.linkToReport()
                          },
                        },
                      },
                      [_vm._v("\n        Descargar reporte\n      ")]
                    ),
                _c(
                  "vs-button",
                  {
                    staticClass: "button-report",
                    staticStyle: { background: "#640064" },
                    attrs: { type: "gradient", target: "_blank" },
                    on: {
                      click: function ($event) {
                        return _vm.linkToResumen()
                      },
                    },
                  },
                  [_vm._v("\n        Descargar resumen\n      ")]
                ),
              ],
              1
            ),
            _c("br"),
            _c("modal-message", { attrs: { modalActive: _vm.progressReport } }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }