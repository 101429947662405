var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "text-center" }, [
            _c("h4", [_vm._v("Apoyo fotográfico")]),
          ]),
          _c("br"),
          _c(
            "swiper",
            { attrs: { options: _vm.swiperOption } },
            [
              _vm._l(
                _vm.project.project_photos,
                function (project_photo, index_project_photo) {
                  return _c("swiper-slide", { key: index_project_photo }, [
                    _c("img", {
                      staticClass: "responsive",
                      attrs: {
                        src: _vm.linkArchive(project_photo.url),
                        alt: project_photo.title,
                      },
                    }),
                  ])
                }
              ),
              _c("div", {
                staticClass: "swiper-pagination",
                attrs: { slot: "pagination" },
                slot: "pagination",
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }